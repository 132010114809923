import React, { useState } from "react"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import Tabs from "../../components/common/Tabs"
import Layout, { Context } from "../../components/common/Layout"
import ReactForm from "../../components/common/ReactForm"
import BlueDot from "../../components/common/BlueDot"
import AssessmentComments from "../../components/common/AssessmentComments"
import { AssessmentCommentsWrapper } from "../../components/PerformIntroductoryEvaluation/getIntroductoryEvaluationOptions"
import InsightModule from "../../components/insight/InsightModule"
import {
  mapAgreementData,
  mapCollateral,
} from "../../components/utils/mapAgreements"
import GenerateReportButton from "../../components/common/GenerateReportButton"
import { getCaseReport } from "../../util/insightCaseReport"
import _ from "lodash"
import { useInsightAppInitialized } from "../../components/insight/useInsightAppInitialized"
import ErrorText from "../../components/common/ErrorText"

const loadAppState = (task) => {
  if (task.taskType === "utfor-tapsvurdering") {
    const hasSavedData = !_.isEmpty(task.data)
    if (hasSavedData) return task.data
    return task.context.existingEvaluation ?? {}
  }

  return task.context.existingEvaluation
}

const PerformLossAssessment = (props) => {
  const { flow, task, schema } = props
  const { t } = useTranslation()
  const [app, setApp] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [errors, setErrors] = useState([])
  const [isLoading] = useInsightAppInitialized(app)
  const [appState, setAppState] = useState(loadAppState(task))
  const [isGeneratingReport, setIsGeneratingReport] = useState(false)
  const [generateReportError, setGenerateReportError] = useState(null)
  const [reportUrl, setReportUrl] = useState(null)

  const onFormChange = (values) => {
    setAppState({ ...appState, ...values })
  }

  const commonData = task.context.insightComponentData.commonData

  // Build collateral objects from collateral agreements if they have an object on them. Check the datastructure in insight to see which properties are needed. Thank you ida.
  const { collateralAgreements, collateralObjects } = mapCollateral(
    [
      ...(flow.data?.mapped?.existingCollateralFromCoreview ?? []),
      ...(flow.data?.mapped?.createdCollateralObjects ?? []),
    ],
    {},
    false
  )

  const isLossAssessmentPerson =
    task.context.insightComponentData.isLossAssessmentPerson

  const agreementData = mapAgreementData(
    {
      agreementData: flow.data?.mapped?.collateralEngagements,
      changesData: [],
      collateral: {
        collateralAgreements,
        collateralObjects,
      },
    },
    flow,
    task.context.guarantors ?? [],
    undefined,
    true
  )

  const data = {
    ..._.merge(
      {
        commonData: {
          ...commonData,
          hasLoan:
            (task.context.insightComponentData?.engagement?.loans?.length ??
              0) > 0,
        },
        isTapsVurdering: true,
        isLossAssessmentPerson,
        caseOverview: task.context.insightComponentData.caseOverview,
        engagement: task.context.insightComponentData.engagement,
        lossAssessment: task.context.insightComponentData.lossAssessment,
      },
      appState
    ),
    ...{
      agreementData,
      creditGroup: task.context.insightComponentData.creditGroup,
    },
  }

  const handleDownloadCaseReport = async () => {
    const payload = {
      ...data,
      shouldDisplayPrognose: !isLossAssessmentPerson,
      context: "lossAssessment",
    }
    const customerId =
      task.context.applicant.orgNr ?? task.context.applicant.ssn

    setIsGeneratingReport(true)

    try {
      const report = await getCaseReport({
        customerId,
        flowId: flow.flowId,
        data: payload,
        locale: "nb-NO",
        customerType: isLossAssessmentPerson ? "Person" : "Company",
      })

      const url = window.URL.createObjectURL(
        new Blob([report.data], { type: "application/pdf" })
      )
      setIsGeneratingReport(false)
      setReportUrl(url)
    } catch (error) {
      console.error("Failed to generate case report", error)
      setGenerateReportError(error)
    } finally {
      setIsGeneratingReport(false)
    }
  }

  const options = [
    {
      id: "overview",
      title: t("overview"),
      component: (
        <InsightModule
          name={"@stacc/vue-casememo"}
          data={data}
          onAppChange={setApp}
          options={{
            context:
              task.taskType === "utfor-tapsvurdering"
                ? "lossAssessment"
                : "lossAssessmentDecision",
          }}
          commonData={commonData}
        />
      ),
    },
    ...(task.taskType === "utfor-tapsvurdering"
      ? [
          {
            id: "accounting",
            title: t("accounting"),
            component: (
              <InsightModule
                name={"@stacc/vue-corporateanalysis"}
                data={{
                  commonData,
                }}
                onAppChange={setApp}
                options={{
                  context: "accounting",
                }}
                commonData={commonData}
              />
            ),
          },
        ]
      : []),
    {
      id: "assessments",
      title: t("assessment-history"),
      children: flow?.data?.assessmentComments?.length ? (
        <BlueDot>{flow?.data?.assessmentComments?.length}</BlueDot>
      ) : undefined,
      component: (
        <AssessmentCommentsWrapper>
          <AssessmentComments comments={flow?.data?.assessmentComments} t={t} />
        </AssessmentCommentsWrapper>
      ),
    },
  ]

  const handleTabChange = (tab) => {
    const insightState = app?.getCurrentState() || {}
    const { calculatedAccounts, ...restLossAssessment } =
      insightState?.lossAssessmentSurvey?.lossassessment ?? {}

    const lossDetailAccounts =
      calculatedAccounts ??
      task.context.insightComponentData.lossAssessment.lossDetailAccounts

    const isLoanDefaultLikely =
      restLossAssessment.isLoanDefaultLikely ??
      appState.lossAssessment?.isLoanDefaultLikely

    setAppState(
      _.merge(appState, data, insightState, {
        lossAssessment: {
          lossDetailAccounts,
          isLoanDefaultLikely,
          ...restLossAssessment,
        },
      })
    )
  }

  const handleComplete = () => {
    setIsProcessing(true)
    const {
      validations: insightValidations,
      surveysNotAnswered,
      ...insightState
    } = app?.getCurrentState() ?? {}

    const { calculatedAccounts, ...restLossAssessment } =
      insightState?.lossAssessmentSurvey?.lossassessment ?? {}

    const lossDetailAccounts =
      calculatedAccounts ??
      task.context.insightComponentData.lossAssessment.lossDetailAccounts

    const isLoanDefaultLikely =
      restLossAssessment.isLoanDefaultLikely ??
      appState.lossAssessment?.isLoanDefaultLikely

    const newData = _.merge(appState, data, insightState, {
      lossAssessment: {
        lossDetailAccounts,
        isLoanDefaultLikely,
        ...restLossAssessment,
      },
      shouldDisplayPrognose: !isLossAssessmentPerson,
      context: "lossAssessment",
    })

    if (newData.caseworkerEvaluation === "SEND_TO_APPROVAL") {
      let errors = []
      const validations = insightValidations ?? appState.validations
      if (validations) {
        if (!validations.caseOverviewComplete)
          errors.push("caseOverviewComplete")
        if (!validations.engagementAnswered) errors.push("engagementAnswered")
      }
      setErrors(errors)
      if (errors.length > 0) {
        setIsProcessing(false)
        return
      }
    }

    props.complete(
      newData,
      () => {
        setIsProcessing(false)
      },
      () => {
        setIsProcessing(false)
        console.error("Could not complete task")
      }
    )
  }
  const handleSave = () => {
    setIsProcessing(true)
    const { validations, surveysNotAnswered, ...insightState } =
      app?.getCurrentState()

    const { calculatedAccounts, ...restLossAssessment } =
      insightState.lossAssessmentSurvey?.lossassessment ?? {}
    const lossDetailAccounts =
      insightState.lossAssessmentSurvey?.lossassessment.calculatedAccounts ??
      task.context.insightComponentData.lossDetailAccounts

    const isLoanDefaultLikely =
      restLossAssessment.isLoanDefaultLikely ??
      appState.lossAssessment?.isLoanDefaultLikely
    props.save(
      _.merge(appState, data, insightState, {
        lossAssessment: {
          lossDetailAccounts,
          isLoanDefaultLikely,
          ...restLossAssessment,
        },
      }),
      () => setIsProcessing(false),
      () => {
        console.error("Could not save task")
        setIsProcessing(false)
      }
    )
  }

  const rightColumnContext = {
    applicant: task.context.applicant,
    lossAssessmentReason: task.context.lossAssessmentReason,
    period: task.context.period,
  }

  return (
    <Layout forceHeight>
      <BMAnalyzeContent>
        <Tabs
          defaultTab={"overview"}
          loading={isLoading}
          options={options}
          onChange={handleTabChange}
        />
      </BMAnalyzeContent>

      <Context
        flow={flow}
        context={rightColumnContext}
        GenerateReportButton={
          task.taskType !== "utfor-tapsvurdering" ? (
            <GenerateReportButton
              handleDownloadCaseReport={handleDownloadCaseReport}
              isGeneratingReport={isGeneratingReport}
              reportUrl={reportUrl}
              generateReportError={generateReportError}
              isProcessing={isProcessing}
              t={t}
            />
          ) : null
        }
      >
        <ReactForm
          schema={schema}
          formData={appState}
          disabled={isProcessing}
          onSubmit={handleComplete}
          onChange={(values) => onFormChange(values)}
        >
          {errors.length > 0 &&
            errors.map((error) => (
              <ErrorContainer>
                <ErrorText error={t(error)} />
              </ErrorContainer>
            ))}
          <ButtonContainer>
            <PrimaryButton
              type="submit"
              isLoading={isProcessing}
              disabled={isLoading || isProcessing}
            >
              {t("complete")}
            </PrimaryButton>
            <SecondaryButtonModified
              type="button"
              disabled={isProcessing}
              onClick={() => handleSave()}
            >
              {t("save")}
            </SecondaryButtonModified>
          </ButtonContainer>
        </ReactForm>
      </Context>
    </Layout>
  )
}

const ErrorContainer = styled.div`
  background-color: #fbeceb;
  padding: 0.75em;
  margin-top: 10px;
  border-radius: 4px;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
`

const BMAnalyzeContent = styled.div`
  height: 100%;
  width: 150%;
  border-right: 1px solid #e4e2e2;
`

const SecondaryButtonModified = styled(SecondaryButton)`
  margin: 0em -1em 0em 1em;
`

export default PerformLossAssessment
