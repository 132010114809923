/* eslint-disable react/prop-types */
/* eslint-disable no-case-declarations */
import React from "react"
import Summary from "./components/caseSummary/Summary"
import DepotControlSummary from "./components/caseSummary/DepotControlSummary"
import LossAssessmentSummary from "./components/caseSummary/LossAssessmentSummary"
import YearlyLossAssessmentSummary from "./components/caseSummary/YearlyLossAssessmentSummary"
import { useTranslation } from "react-i18next"

const CaseSummary = ({ caseData }) => {
  const { t } = useTranslation()
  const { data } = caseData || {}
  const flowDefinition = caseData.flowDefinitionId
  const category = data?.application?.category
  const isManualApplication = data?.application?.isManualApplication
  const subcategories = data?.application?.subcategories
  const applyingBank = data?.application?.applyingBank
  const applyingCustomer = data?.application?.applyingCustomer
  const customerData = data.raw?.accountMaster || {}
  const customerAccount = customerData?.customerAccount || {}
  const customerContact = customerData?.customerContactApplication || []
  const application = data?.raw?.applicationMaster?.currentApplication || {}
  const projectName = data?.mapped?.caseOverview?.projectName
  const impartiability =
    data?.raw?.impartiability?.length > 0
      ? t("hits-impartiability-check")
      : t("no-hits-impartiability-check")

  let decideIddRequirements =
    data?.decisionTables?.decideIddRequirements?.output?.performIdd
  const manualAssessment = data?.integrityDueDilligence?.manualAssessment
  const urls = data?.application?.urls || []
  const urlForServiceDirectoryIN =
    "https://innovationnorway.sharepoint.com/sites/InBusiness365_Dev/SitePages/Søk-i-tjenester.aspx"

  if (flowDefinition === "depot-end-control") {
    const applicant = data.input.applicant
    const followUpDate = data.input.followUpDate
    const caseNumber = data.input.caseNumber
    const companyName = data.input.companyName

    return (
      <DepotControlSummary
        applicant={applicant}
        followUpDate={followUpDate}
        caseNumber={caseNumber}
        companyName={companyName}
      />
    )
  }

  if (flowDefinition === "tapsvurdering") {
    return (
      <LossAssessmentSummary
        year={data.caseData?.year}
        customerAccount={customerAccount}
        caseId={data.caseData?.customer.caseId}
        parentFlowId={data.caseData?.parentFlowId}
      />
    )
  }

  if (flowDefinition === "tapsavsetning") {
    return (
      <YearlyLossAssessmentSummary
        year={data.caseData?.year}
        lossAssessments={data.caseData?.lossAssessments}
        csv={data.csv}
        cancelled={data.cancelled}
        createdAt={caseData.createdAt}
      />
    )
  }

  return flowDefinition === "payout-control" ? (
    ""
  ) : (
    <Summary
      category={category}
      subcategories={subcategories}
      customerAccount={customerAccount}
      customerContact={customerContact}
      application={application}
      impartiability={impartiability}
      decideIddRequirements={decideIddRequirements}
      manualAssessment={manualAssessment}
      urls={urls}
      urlForServiceDirectoryIN={urlForServiceDirectoryIN}
      applyingCustomer={applyingCustomer}
      applyingBank={applyingBank}
      projectName={projectName}
      isManualApplication={isManualApplication}
    />
  )
}

export default CaseSummary
