import { isValid } from "date-fns"
import _ from "lodash"
import oldProductIdMapping from "../../../constants/oldProductIdMapping"
import formatWithLocale from "../../../customer/util/dateFormatter"
import { DropDownValues } from "../common/tempData/DropDownValues"

const isValidDate = (date) => {
  return isValid(new Date(date))
}

const compareDates = (dateA, dateB) => {
  return isValidDate(dateA) ? dateA > dateB : false
}

const getDate = (dateA, dateB, getLatest) => {
  const firstDate = new Date(dateA)
  const secondDate = new Date(dateB)
  return getLatest
    ? compareDates(firstDate, secondDate)
      ? firstDate
      : secondDate
    : compareDates(firstDate, secondDate)
    ? secondDate
    : firstDate
}

const swapDate = (compare, current, getLatest) => {
  return !current && isValidDate(compare)
    ? new Date(compare)
    : getDate(compare, current, getLatest)
}

export const formatInputDate = (date) => {
  return date && isValidDate(date)
    ? formatWithLocale(new Date(date), "yyyy-MM-dd")
    : "yyyy-MM-dd"
}

export const computeCosts = (costs = []) => {
  return costs.reduce((acc, curr) => acc + parseFloat(curr.approved), 0)
}

const pushToActivityAndSubsidyRegime = (
  activityAndSubsidyRegime,
  currentIndex,
  subsidyRegime
) => {
  if (
    !activityAndSubsidyRegime
      ?.map((e) => e.activity === currentIndex + 1)
      ?.includes(true)
  ) {
    activityAndSubsidyRegime.push({
      activity: currentIndex,
      subsidyRegime,
    })
  }
}
/*
Go through list of current subproducts
if current subproduct in previous, splice and update amount, add subproduct back in
if not in previous, add
 */

const pushToSubproducts = (
  productName,
  previousSubproducts,
  currentSubproducts
) => {
  let clone = _.cloneDeep(previousSubproducts)
  if (currentSubproducts?.length > 0) {
    currentSubproducts.forEach((sub) => {
      const currentSubIndex = clone.findIndex(
        (elem) => elem.variable === sub.variable
      )
      if (currentSubIndex >= 0) {
        const subProductToChange = clone.splice(currentSubIndex, 1)[0]
        subProductToChange.amount =
          parseFloat(subProductToChange.amount) + parseFloat(sub.amount)
        clone = [...clone, subProductToChange]
      } else {
        clone = [...clone, sub]
      }
    })
  }
  return clone
}

const mapDeliveries = (activity, currentIndex, acc, productConfiguration) => {
  //add new product to object or add to existing object
  return activity?.products?.forEach((curr) => {
    const { productType, amount, subproducts } = curr
    const productId = oldProductIdMapping[curr.product] || curr.product
    curr.product = productId
    const productName =
      productConfiguration[productId]?.displayNameShort || productId
    if (acc && productId in acc) {
      //replace date if larger/smaller
      acc[productId].deliveryStart = getDate(
        activity?.activityStart,
        acc[productId]?.deliveryStart,
        false
      )
      acc[productId].deliveryEnd = getDate(
        activity?.activityEnd,
        acc[productId]?.deliveryEnd,
        true
      )
      //increment amount
      acc[productId].amount += parseFloat(amount)

      pushToActivityAndSubsidyRegime(
        acc[productId]?.activityAndSubsidyRegime,
        currentIndex,
        activity?.supportRegime
      )
      acc[productId].subproducts = pushToSubproducts(
        productId,
        acc[productId].subproducts,
        subproducts
      )
    } else {
      acc[productId] = {
        productName,
        deliveryStart:
          isValidDate(activity?.activityStart) &&
          new Date(activity?.activityStart),
        deliveryEnd:
          isValidDate(activity?.activityEnd) && new Date(activity?.activityEnd),
        amount: parseFloat(amount),
        subproducts,
        productType,
        activityAndSubsidyRegime: [
          {
            activity: currentIndex,
            subsidyRegime: activity?.supportRegime,
          },
        ],
      }
    }
    return acc
  }, {})
}

export const mapCaseInput = (caseInput, productConfiguration) => {
  if (!caseInput) {
    return { activityTypes: [], products: {}, startDate: null, endDate: null }
  }
  const mapped = caseInput?.activities?.reduce(
    (acc, activity, currentIndex) => {
      acc.startDate = swapDate(activity?.activityStart, acc?.startDate, false)
      acc.endDate = swapDate(activity?.activityEnd, acc?.endDate, true)
      mapDeliveries(activity, currentIndex, acc.products, productConfiguration)
      if (!acc?.activityTypes?.includes(activity?.activityType)) {
        acc.activityTypes.push(activity?.activityType)
      }
      return acc
    },
    { activityTypes: [], products: {}, startDate: null, endDate: null }
  )

  return mapped
}

export const mapTotalProposed = (mappedInputProducts, productConfiguration) => {
  if (!mappedInputProducts) return { loan: 0, grant: 0, guarantee: 0 }
  return Object.keys(mappedInputProducts).reduce(
    (acc, curr) => {
      if (curr !== "noOption") {
        const productClass = productConfiguration[curr].productClass
        const productType = productConfiguration[curr].productType
        if (productClass === "Loan") {
          acc.loan += mappedInputProducts[curr].amount || 0
          if (productType === "loan-with-risk") {
            acc.loanTotalHighRisk += mappedInputProducts[curr].amount || 0
          }
          if (productType === "low-risk-loan") {
            acc.loanTotalLowRisk += mappedInputProducts[curr].amount || 0
          }
        }
        if (productClass === "Grant") {
          acc.grant += mappedInputProducts[curr].amount || 0
        }
        if (productClass === "Guarantee") {
          acc.guarantee += mappedInputProducts[curr].amount || 0
        }
      }
      return acc
    },
    {
      loan: 0,
      grant: 0,
      guarantee: 0,
      loanTotalHighRisk: 0,
      loanTotalLowRisk: 0,
    }
  )
}

export const mapCostOverview = (caseInput) => {
  const allCosts = caseInput.map((input) => {
    return input.costs.reduce((acc, curr) => {
      acc.push({
        name: curr.name,
        variable: curr.variable,
        appliedFor: parseFloat(curr.appliedFor),
        approved: parseFloat(curr.approved),
      })
      return acc
    }, [])
  })

  return Object.values(
    allCosts.reduce((acc, costList) => {
      costList.forEach((cost) => {
        if (acc[cost.variable]) {
          acc[cost.variable] = {
            name: cost.name,
            variable: cost.variable,
            appliedFor: acc[cost.variable].appliedFor + cost.appliedFor,
            approved: acc[cost.variable].approved + cost.approved,
          }
        } else {
          acc[cost.variable] = cost
        }
      })

      return acc
    }, {})
  )
}

// Mapping of supportRegime from DropDownValues.jsx to eosArticle for the product registry
const supportRegimeMapping = {
  trivialSupport: "artBAG",
  regionalInvestment: "art14",
  smbInvestment: "art17",
  smbConsultantSupport: "art18",
  smbConferenceParticipation: "art19",
  smbEtcProjects: "art20",
  riskFinancingSupport: "art21",
  startUps: "art22",
  researchAndDevelopment: "art25",
  researchInfrastructure: "art26",
  clusterSupport: "art27",
  smbInnovationSupport: "art28",
  processAndInnovation: "art29",
  fouFishingAndAquaCulture: "art30",
  training: "art31",
  increaseProtectionOfEnvironment: "art36",
  earlyAdaptation: "art37",
  energySaving: "art38",
  highEfficientCogenerationPlant: "art40",
  renewableEnergy: "art41",
  revertPolutedAreas: "art45",
  sufficientDistrictHeatingAndCooling: "art46",
  recyclingAndReusing: "art47",
  energyInfrastructure: "art48",
  studiesOfEnvironment: "art49",
  cultureAndCulturalHeritage: "art53",
  audioVisualWorks: "art54",
  sportAndRecreationalInfrastructure: "art55",
  localInfrastructure: "art56",
  exemptFromSupportBasisRules: "artNOTEØS",
  article14: "art14",
  article17: "art17",
  article18: "art18",
  article19: "art19",
  article20: "art20",
  article21: "art21",
  article22: "art22",
  article25: "art25",
  article26: "art26",
  article27: "art27",
  article28: "art28",
  article29: "art29",
  article30: "art30",
  article31: "art31",
  article36: "art36",
  article37: "art37",
  article38: "art38",
  article40: "art40",
  article41: "art41",
  article45: "art45",
  article46: "art46",
  article47: "art47",
  article48: "art48",
  article49: "art49",
  article53: "art53",
  article54: "art54",
  article55: "art55",
  article56: "art56",
  article561: "art561",
  article564: "art56e4",
  article566: "art56e6",
  article567: "art56e7",
  article568: "art56e8",
  article569: "art56e9",
  article5610: "art56e10",
  articleNOTEØS: "artNOTEØS",
  articleTF: "artTF",
}

export const getNewSupportRegime = (supportRegime) => {
  return supportRegimeMapping[supportRegime] || supportRegime
}

export const getOldSupportRegime = (supportRegime) => {
  return Object.keys(supportRegimeMapping).find(
    (key) => supportRegimeMapping[key] === supportRegime
  )
}

export const getSupportRegimesFromProducts = (productConfiguration) => {
  // For each product, fetch supportRegime from product.eosArticles and push to array with type {name: supportRegime, value: supportRegime}
  const supportRegimesFromProducts = Object.keys(productConfiguration).reduce(
    (acc, curr) => {
      const { eosArticles } = productConfiguration[curr]
      if (eosArticles?.length > 0) {
        const supportRegimes = eosArticles.map((eosArticle) =>
          getOldSupportRegime(eosArticle)
        )
        acc.push(...supportRegimes)
      }

      // Get the unique supportRegimes from the array
      const uniqueSupportRegimes = [
        ...new Set(acc.map((supportRegime) => supportRegime)),
      ]
      return uniqueSupportRegimes
    },
    []
  )
  const mappedSupportRegimes = supportRegimesFromProducts.map(
    (supportRegime) => {
      return {
        name: supportRegime,
        value: supportRegime,
      }
    }
  )

  const customOrder = [
    "art-56-e-4",
    "art-56-e-7",
    "art-56-e-6",
    "art-56-e-8",
    "art-56-e-9",
    "art-56-e-10",
    "exemptFromSupportBasisRules",
    "trivialSupport",
  ]
  const mergedDropdownValues = [
    ...DropDownValues.SupportRegime,
    ...DropDownValues.SupportRegimeExtraordinaryFinancing,
    ...DropDownValues.SupportRegimeBioEnergi,
    ...DropDownValues.SupportRegimeDtil,
    ...DropDownValues.SupportRegimeExportPromotion,
    ...DropDownValues.SupportRegimeDSF,
    ...DropDownValues.NotMappedSupportRegimes,
  ]

  const mappedSupportRegimesWithNames = mappedSupportRegimes
    .map(({ value, name }) => {
      // Find the corresponding object in the SupportRegime array

      const regime = mergedDropdownValues.find((item) => item.value === value)

      // If the object is found, return the name, otherwise return the original value
      return {
        value: regime?.value ?? value,
        name: regime?.name ?? name,
      }
    })
    .filter(
      (regime) =>
        !customOrder.includes(regime.name) &&
        !customOrder.includes(regime.value)
    )
    .sort((a, b) =>
      a.name.localeCompare(b.name, "en", { numeric: true, sensitivity: "base" })
    )
    .concat(
      mergedDropdownValues
        .filter((regime) => customOrder.includes(regime.name))
        .sort(
          (a, b) => customOrder.indexOf(a.name) - customOrder.indexOf(b.name)
        )
    )

  const uniqueMappedSupportRegimes = [
    ...new Map(
      mappedSupportRegimesWithNames.map((item) => [item.name, item])
    ).values(),
  ]
  return uniqueMappedSupportRegimes
}

/**
 * @typedef {object} Product
 * @property {String} product - The product id
 * @property {String} amount - The amount for the product
 * @property {String} fundingIntensity - The funding intensity for the product
 * @property {"loan-with-risk"|"grant"|"low-risk-loan"|"Garanti"} productType - The product type for the product - eg. "loan-with-risk"
 * @property {String} subsidyEffect - The subsidy effect for the product
 * @property {String[]} subproducts - The subproducts for the product
 * @property {String[]} eosArticles - The eosArticles for the product
 */

/**
 * @typedef {object} Activity
 * @property {String} activityType - The type of the activity, eg. "startup"
 * @property {String} supportRegime - The supportRegime for the activity, eg. "trivialSupport"
 * @property {Product[]} products - The products for the activity
 * @property {String} activityStart - The start date of the activity
 * @property {String} activityEnd - The end date of the activity
 * @property {Cost[]} costs - The costs for the activity
 * @property {String} activityTitle - The title of the activity
 * @property {Number} inputTotal - The input total for the activity
 * @property {Number} staticTotal - The static total for the activity
 * @property {Boolean} isCustomActivity - If the activity is a custom activity
 *
 * @typedef {object} Cost - The cost for the activity
 * @property {String} name - The name of the cost
 * @property {String} variable - The variable of the cost
 * @property {String} appliedFor - The applied for amount of the cost
 * @property {String} approved - The approved amount of the cost
 */

/**
 * Validates the products in the activities based on the productsFromRegistry.
 *
 * @param {Activity[]} data - The activities to validate.
 * @param {ProductsFromRegistry} productsFromRegistry - The registry of products.
 * @returns {object} - Returns products that are invalid for the given supportRegime.
 */
export const validateAndShowInvalidProducts = (data, productsFromRegistry) => {
  const invalidProducts = []
  for (const activity of data) {
    for (const product of activity.products) {
      // Find the corresponding product in the productsFromRegistry
      /**
       * @type {ProductFromRegistry}
       */
      const correspondingProduct = productsFromRegistry[product.product]
      // If the product was not found or the supportRegime is not included in the eosArticles, return false
      if (
        !correspondingProduct ||
        !correspondingProduct?.eosArticles?.includes(
          getNewSupportRegime(activity.supportRegime)
        )
      ) {
        invalidProducts.push({
          ...product,
          displayName: correspondingProduct?.displayName ?? "noOption",
        })
      }
    }
  }
  // If all products passed the validation, return true
  return invalidProducts
}

export const shouldValidateProducts = (hasEosArticles, isDynamicCostBased) => {
  return hasEosArticles && isDynamicCostBased
}

export const mapTotalProjectCostAndBasisForSupportForCostOverview = (costs) => {
  return costs.reduce(
    (acc, curr) => {
      const appliedForTotal = acc.projectCost + curr.appliedFor
      const approvedTotal = acc.basisForSupport + curr.approved

      acc.projectCost = appliedForTotal
      acc.basisForSupport = approvedTotal
      return acc
    },
    { projectCost: 0, basisForSupport: 0 }
  )
}

/**
 * @typedef {Object.<string, ProductFromRegistry>} ProductsFromRegistry
 * @typedef {object} ProductFromRegistry
 * @property {Boolean} isActive
 * @property {String[]} category - eg ["humanitarianinnovationproject"]
 * @property {String} productName
 * @property {String} marketingName
 *
 * @property {String} productId
 * @property {String} displayName
 * @property {"grant"|"loan"} productType
 * @property {String} productClass - eg "Grant" or "Loan"
 * @property {String[]} eosArticles
 * @property {String} displayNameShort
 * @property {String} displayNameShortVmCode
 * @property {String} typeCode
 * @property {Boolean} canSignWithProkura
 * @property {Boolean} isDpv
 * @property {Boolean} canSkipFinancialCheck
 * @property {Boolean} canSkipFinancialAnalysis
 * @property {Boolean} showTaxonomy
 * @property {String} [loanPurposeCode] - Loan specific
 * @property {number} [safetyRisk] - Loan specific
 * @property {number} [upsideFeePercentage] - Loan specific
 */

/**
 * @typedef {object} BasicProductInformation - The basic information for the product
 * @property {String} productCode - The product code
 * @property {String} productName - The product name
 * @property {String} marketingName - The marketing name
 * @property {String} productDescription - The product description
 * @property {Array} productCategories - The product categories
 * @property {object} productStatus - The product status
 * @property {Boolean} productStatus.isActive - If the product is active
 */

/**
 * @typedef {object} ProductFromControlPanel
 * @property {String} internalId
 * @property {String} externalId
 * @property {BasicProductInformation} basicProductInformation
 * @property {object} customData
 * @property {object} customData.customFields - The custom fields for the product
 * @property {object} customData.processFields - The process fields for the product
 * @property {object} loanAmount - The loan amount for the product
 * @property {number} loanAmount.min - The min amount for the product
 * @property {number} loanAmount.max - The max amount for the product
 * @property {object} maturity - The maturity for the product
 * @property {number} maturity.maxMaturity - The max maturity for the product
 * @property {number} maturity.minMaturity - The min maturity for the product
 * @property {object} gracePeriod - The grace period for the product
 * @property {number} gracePeriod.maxGracePeriod - The max grace period for the product
 * @property {Boolean} gracePeriod.gracePeriodAllowed - If the grace period is allowed
 * @property {object} interestFreePeriod
 * @property {Boolean} interestFreePeriod.interestFreePeriodAllowed
 * @property {number} interestFreePeriod.maxinterestFreePeriod
 * @property {object} loanType
 * @property {String[]} loanType.loanTypeOptions
 * @property {"SERIAL"|"ANNUITY"} loanType.loanTypeDefault
 * @property {PaymentFrequencyProduct} paymentFrequency
 * @property {InterestProfile[]} interestProfiles
 * @property {ProductFeeRates[]} feeRates
 *
 *
 * @typedef {import("../utils/mapAgreements").PaymentFrequency} PaymentFrequency
 *
 * @typedef {object} PaymentFrequencyProduct
 * @property {PaymentFrequency[]} paymentFrequency - The payment frequency options, array of strings, e.g ["MONTHLY", "QUARTERLY"]
 * @property {PaymentFrequency} paymentFrequencyDefault - The default payment frequency for the product
 *
 *
 * @typedef {object} ProductFeeRates
 * @property {String} basicRateId
 * @property {String} code
 * @property {String} description
 * @property {"ESTABLISHMENT_FEE"|"INSTALLMENT_FEE"|"OTHER"} type
 * @property {String} name
 * @property {Number} amount
 * @property {Boolean} selected
 *
 * @typedef {object} InterestProfile
 * @property {String} interestProfileId
 * @property {"DEBIT_INTEREST"|"CREDIT_INTEREST"} interestRateType
 * @property {Boolean} accumulateInterest
 * @property {"FIXED_INTEREST_RATE"|"FLOATING_INTEREST_RATE"} interestRateAdjustmentType
 * @property {InterestLine[]} interestLines
 *
 * @typedef {object} InterestLine
 * @property {InterestRates} interestRates
 *
 * @typedef {object} InterestRates
 * @property {BasicRate} baseRate
 *
 * @typedef {object} BasicRate
 * @property {String} basicRateId
 * @property {String} name
 * @property {Number} value
 * @property {String} suffix - eg "%"
 */
