import React from "react"
import styled from "styled-components"
import ProposedAmountBox from "./ProposedAmountBox"
import ActivitiesOverview from "./activitiesOverview/ActivitiesOverview"
import SidebarMenu from "./sidebar/SidebarMenu"
import Activity from "./activity/Activity"
import SidebarItem from "./sidebar/SidebarItem"
import { ProjectColors } from "../common/style/ProjectColors"
import { addThousandSeparator } from "../../util/addThousandSeparator"
import AddSidebarItem from "./sidebar/AddSidebarItem"

const EvaluateBasisForSupportComponent = ({
  t,
  flow,
  data,
  options,
  functions,
}) => {
  const {
    activeActivity,
    caseInput,
    totalOriginalCosts,
    totalAppliedFor,
    totalProposed,
    mappedInputData,
    currency,
    category,
  } = data
  const {
    handleChangeActivity,
    handleBlur,
    clearForm,
    setClearForm,
    getRecentValues,
    setGetRecentValues,
    addActivity,
    removeActivity,
    editCost,
    addCosts,
    removeCosts,
    createClone,
    resetCaseInput,
  } = functions

  const FINANCIAL_GUIDE_LINK =
    "https://innovationnorway.sharepoint.com/sites/Finansieringshandboken#4.12-statsst%C3%B8ttegrunnlag"

  return (
    <EvaluateBasisForSupportWrapper>
      <StyledLinkWrapper>
        <StyledLink href={FINANCIAL_GUIDE_LINK}>
          {t("financialGuideLink")}
        </StyledLink>
      </StyledLinkWrapper>
      <ProposedAmountBoxWrapper>
        <ProposedAmountBox
          title={t("proposed-grant").toUpperCase()}
          currency={currency}
          amount={addThousandSeparator(totalProposed.grant)}
          appliedForAmount={addThousandSeparator(totalAppliedFor.grant)}
          appliedForText={t("applied-for")}
        />
        <ProposedAmountBox
          title={t("proposed-loan").toUpperCase()}
          currency={currency}
          amount={addThousandSeparator(totalProposed.loan)}
          appliedForAmount={addThousandSeparator(totalAppliedFor.loan)}
          appliedForText={t("applied-for")}
        />
        <ProposedAmountBox
          title={t("proposed-guarantee").toUpperCase()}
          currency={currency}
          amount={addThousandSeparator(totalProposed.guarantee)}
          appliedForAmount={addThousandSeparator(totalAppliedFor.guarantee)}
          appliedForText={t("applied-for")}
        />
      </ProposedAmountBoxWrapper>

      <Heading>{t("activities")}</Heading>
      <EvaluateBasisLayout
        gridTemplateColumns={options.isSingleActivity && "1fr"}
      >
        {!options.isSingleActivity && (
          <SidebarMenu>
            <SidebarItem
              active={activeActivity === "overview"}
              onClick={handleChangeActivity}
              index={"overview"}
              t={t}
              currency={currency}
              total={addThousandSeparator(totalOriginalCosts)}
              canDelete={false}
            />
            {caseInput.activities.map((activity, index) => {
              return (
                <SidebarItem
                  active={index === activeActivity}
                  onClick={handleChangeActivity}
                  index={index}
                  key={`${index}sidebarItem`}
                  currency={currency}
                  total={addThousandSeparator(activity?.staticTotal)}
                  t={t}
                  name={activity?.activityTitle}
                />
              )
            })}
            <AddSidebarItem addActivity={addActivity} t={t} />
          </SidebarMenu>
        )}
        {activeActivity === "overview" && (
          <ActivitiesOverview
            t={t}
            name={t("overview")}
            productSummary={mappedInputData.products}
            activityTypes={mappedInputData.activityTypes}
            startDate={mappedInputData.startDate}
            endDate={mappedInputData.endDate}
            caseInput={caseInput.activities}
            activeActivity={activeActivity === "overview"}
            editCost={editCost}
            addCosts={addCosts}
            removeCosts={removeCosts}
            resetCaseInput={resetCaseInput}
            createClone={createClone}
            currency={currency}
            isDynamicCostBased={
              flow?.data?.application?.applicationContent?.isDynamicCostBased ||
              false
            }
          />
        )}
        {activeActivity >= 0 && (
          <>
            {caseInput && caseInput && (
              <Activity
                key={activeActivity}
                caseInput={caseInput?.activities[activeActivity]}
                currency={currency}
                index={activeActivity}
                t={t}
                handleBlur={handleBlur}
                setClearForm={setClearForm}
                getRecentValues={getRecentValues}
                setGetRecentValues={setGetRecentValues}
                clearForm={clearForm}
                options={options}
                category={category}
                isDynamicCostBased={
                  flow?.data?.application?.applicationContent
                    ?.isDynamicCostBased || false
                }
                canDelete={
                  flow?.data?.application?.applicationContent
                    ?.isDynamicCostBased || false
                }
                onDelete={(e) => {
                  e.stopPropagation()
                  removeActivity(activeActivity)
                }}
              />
            )}
          </>
        )}
      </EvaluateBasisLayout>
    </EvaluateBasisForSupportWrapper>
  )
}

export default EvaluateBasisForSupportComponent

const EvaluateBasisForSupportWrapper = styled.div`
  padding: 10px 40px 50px 40px;
  display: flex;
  flex-direction: column;
`
const ProposedAmountBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

const Heading = styled.p`
  font-weight: 600;
  margin-top: 15px;
  margin-bottom: 10px;
`

const EvaluateBasisLayout = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.gridTemplateColumns || "2fr 10fr"};
  padding-top: 0;
  border: solid 1px ${ProjectColors.SlateBoxShadow};
  border-radius: 5px;
  min-height: 30em;
`

const StyledLinkWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-bottom: 10px;
`

const StyledLink = styled.a`
  text-decoration: underline;
  color: ${ProjectColors.Blue};
  cursor: pointer;
`
